import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MdDeleteForever } from 'react-icons/md';
import CustomModal from '../../component/UI Component/Modal';
import axios from 'axios';

const List_Register_Store_Manager = () => {
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_API_STORE_MANAGERS.toString();
    const deleteapiname = process.env.REACT_APP_API_SM_UPDATE_DELETE.toString();
    
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const itemsPerPage = 10;

    const query = new URLSearchParams({
        search: inputValue !== "" ? inputValue : "",
        page: currentPage,
        limit: 10,
    }).toString();
    const { data, error, isFetching, refetch } = useFetch(`${apiname}?${query}`);

    useEffect(() => {
        if(isFetching && error === null){
            refetch();
        }
    }, [inputValue, currentPage]);

    if (!data) return null;

    const totalPages = Math.ceil(data?.totalRows / itemsPerPage);

    const handleInputChange = (event,key) => {
        setInputValue(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const removeTheStoreUser = (userId) => {
        setUserId(userId)
        setOpen(true)
    }

    const handleYes = () => {
        setOpen(false);
        deleteUser()
    };

    const handleNo = () => {
        setOpen(false);
    };

    const deleteUser = async () => {
        const localData = JSON.parse(localStorage.getItem("items"))
        const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}${deleteapiname}/${userId}`, {
            headers: {
                'authorization': `Bearer ${localData?.token}`
            }
        })
        await refetch();
    }


    return (
        <>
            <div className='flex justify-end mb-2'>
                <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={() => navigate('/layout/Addstore')}>
                    Add Store Manager
                </button>
            </div>
            {(data === "There is no store manager yet!" || error) ? (
                <p className='flex justify-center text-3xl'>{error ? error.message : "No Store Manager"}</p>
            ) : (
                <>
                        <input
                            type='search'
                            value={inputValue}
                            onChange={handleInputChange}
                            className='flex w-96 border border-gray-300 rounded-lg p-2 ml-auto'
                            placeholder='Search'
                        />
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                            <thead className="text-xs text-white uppercase bg-pink-600 dark:bg-pink-200 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Sr No.</th>
                                    <th scope="col" className="px-6 py-3">Store Manager Name</th>
                                    <th scope="col" className="px-6 py-3">Store Name</th>
                                    <th scope="col" className="px-6 py-3">Store Address</th>
                                    <th scope="col" className="px-6 py-3">Store ID</th>
                                    <th scope="col" className="px-6 py-3">Email</th>
                                    <th scope="col" className="px-6 py-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.map((e, index) => (
                                    <tr key={e.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-pink-50 even:dark:bg-pink-200 border-b dark:border-pink-700">
                                        <td className="px-6 py-4">{index + 1}</td>
                                        <td className="px-6 py-4">{e.store_manager_name}</td>
                                        <td className="px-6 py-4">{e.name}</td>
                                        <td className="px-6 py-4">{e.store_address?.length >= 50 ? e.store_address.slice(0, 50) + '...' : e.store_address}</td>
                                        <td className="px-6 py-4">{e.storeId}</td>
                                        <td className="px-6 py-4">{e.email}</td>
                                        <td className='px-6 py-4 items-center'>

                                            <div className='grid grid-cols-2'>

                                                <button onClick={() => navigate(`/layout/Editstore/${e.id}`)}>
                                                    <FaRegEdit size={20} style={{ color: '#DB2777' }} />
                                                </button>
                                                <button onClick={() => removeTheStoreUser(e.id)} className='mx-1'>
                                                    <MdDeleteForever size={23} style={{ color: '#DB2777' }} />
                                                </button>

                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-end mt-4">
                        <Stack spacing={2} >
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                // color="#DB2777"
                                variant="outlined"
                                shape="rounded"
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        color: '#DB2777',
                                        borderColor: '#DB2777',
                                        backgroundColor: 'transparent',
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: '#DB2777',
                                        color: '#ffffff',
                                        borderColor: '#DB2777',
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        color: '#DB2777',
                                    },
                                }}
                            />
                        </Stack>
                    </div>
                    <CustomModal
                        open={open}
                        onClose={handleNo}
                        onYes={handleYes}
                        onNo={handleNo}
                        text={"Are you sure you want to delete?"}
                    />
                </>
            )}
            {/* {
                isFetching ? (
                    <Loader />
                ) : null
            } */}
        </>
    );
};

export default List_Register_Store_Manager;


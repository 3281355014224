import React, { useEffect } from 'react';
import Emailimg from '../../images/Email.png'
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import  Snackbar  from '@mui/material/Snackbar';
// import authService from '../../service/auth.service;


const Login_User = () => {
  const storeId = localStorage.getItem("storeId")
  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const apiname = process.env.REACT_APP_API_SEND_OTP.toString();
  const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiname}`, data)    
    return postapicall.data
  })
  const navigate = useNavigate()

  const onSubmit = (data) => {
    const submitData = {
      ...data,
      storeId:storeId
    }
    mutate(submitData)
  }

  const [state, setState] = React.useState({
    open: false
  });
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  useEffect(()=>{
    if (isSuccess && storeId) {
      const email = getValues('email')
      const mobile = getValues('mobile')
      localStorage.setItem("userEmail",email)
      localStorage.setItem("mobile",mobile)
      navigate(`/user-otp`)
    }
    else if(isError){
      console.log(isError,"isError");
      setState({open:true})
    }
  },[data,error,isSuccess])
    
  
  return (
    <>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row max-w-4xl p-6 rounded-lg ">

        <div className="hidden md:flex md:w-1/2 items-center justify-center p-4">

          <img src={Emailimg} alt='' />
        </div>

        <div className="w-full md:w-1/2 p-6  bg-white shadow-lg">
          <h2 className="text-2xl font-bold text-center mb-6">Login </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
                Mobile Number
              </label>
              <div className="flex items-center border border-gray-300 rounded-md p-2">
              <svg className="h-8 w-8 text-gray-400" viewBox="-13.32 0 68.514 68.514" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Group_62" data-name="Group 62" transform="translate(-815.597 -920.238)"> <g id="Group_59" data-name="Group 59"> <path id="Path_53" data-name="Path 53" d="M852.706,988.752H820.363a4.773,4.773,0,0,1-4.766-4.767v-58.98a4.773,4.773,0,0,1,4.766-4.767h32.343a4.773,4.773,0,0,1,4.767,4.767v58.98A4.773,4.773,0,0,1,852.706,988.752Zm-32.343-65.514a1.769,1.769,0,0,0-1.766,1.767v58.98a1.769,1.769,0,0,0,1.766,1.767h32.343a1.769,1.769,0,0,0,1.767-1.767v-58.98a1.769,1.769,0,0,0-1.767-1.767Z" fill="#727883"></path> </g> <g id="Group_60" data-name="Group 60"> <rect id="Rectangle_35" data-name="Rectangle 35" width="26.5" height="43.764" rx="2.058" transform="translate(823.285 929.072)" fill="#727883"></rect> </g> <g id="Group_61" data-name="Group 61"> <circle id="Ellipse_76" data-name="Ellipse 76" cx="3.699" cy="3.699" r="3.699" transform="translate(832.836 975.706)" fill="#727883"></circle> </g> </g> </g></svg>                 


                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your Mobile Number"
                  {...register('mobile', {
                    required: 'Mobile number is required',
                    pattern: {
                      value: /^(\+91|91)?[6-9][0-9]{9}$|^\+?1[-.\s]?(\(?[0-9]{3}\)?)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/,
                      message: 'Please enter a valid mobile number',
                    },
                  })}
                />
              </div>
              <p style={{ color: "Red" }}>{errors?.mobile?.message}</p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
                Email ID
              </label>
              <div className="flex items-center border border-gray-300 rounded-md p-2">
                <svg class="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>

                <input
                  type="text"
                  id="email"
                  name="email"
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your Email"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                      message: 'Please enter a valid email',
                    },
                  })}
                  onChange={(e) => {
                    e.target.value = e.target.value.toLowerCase();
                    e.target.form.dispatchEvent(new Event('input', { bubbles: true }));
                  }}
                />
              </div>
              <p style={{ color: "Red" }}>{errors?.email?.message}</p>
            </div>
            <button type='submit' className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700">

              <div className='flex justify-center'>

                <div className='mx-2'>
                  <span> Get OTP</span>
                </div>

                <div>
                  {isLoading && <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
                </div>

              </div>

            </button>

          </form>
        </div>
      </div>
    </div>
     <Snackbar
     anchorOrigin={{ vertical:'top', horizontal:'right' }}
       open={state.open}
       onClose={handleClose}
       TransitionComponent={state.Transition}
       message={error?.response?.data?.message || data?.message}
       key={state?.Transition?.name}
       autoHideDuration={3000}
       />
    </>
  );
};

export default Login_User;


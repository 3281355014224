import React, { useState } from 'react';
import Header from './subComponents/Header';
import Sidebar from './subComponents/Sidebar';
import MobileMenu from './subComponents/MobileMenu';
import { Route, Routes } from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Add_Register_Store from '../pages/Register Store Manager/Add_Register_Store'
import Edit_Register_Store from '../pages/Register Store Manager/Edit_Register_Store'
import List_Register_Store_Manager from '../pages/Register Store Manager/List_Register_Store_Manager'
import List_User from '../pages/User List/List_User'
import PageNotFound from '../pages/PageNotFound';
import Forms from '../pages/Admin/From/Index';
import Form from '../component/Form/Form';
import Edit_Form from '../component/Form/Edit_Form';
import UploadFileFormat from '../pages/Upload file format/upload_file_format'
import List_Files from '../pages/Upload file format/List_Files';
import Edit_User from '../pages/User List/Edit_User';
import List_User_Form from '../pages/User List/List_User_Form';

const Layout = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <div className="bg-gray-100 min-h-screen">
            <Header toggleMobileMenu={toggleMobileMenu} />
            <div className="flex">
                <Sidebar />
                <main className="flex-1 p-4 w-fit sm:w-full overflow-x-auto">
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/Addstore" element={<Add_Register_Store />} />
                        <Route path="/Editstore/:id" element={<Edit_Register_Store />} />
                        <Route path="/liststore" element={<List_Register_Store_Manager />} />
                        <Route path="/listUser" element={<List_User />} />
                        <Route path="/userforms" element={<List_User_Form />} />
                        <Route path="/EditUser/:id" element={<Edit_User />} />
                        <Route path="/form" element={<Forms />} />
                        <Route path="/addForm" element={<Form />} />
                        <Route path="/editForm/:id" element={<Edit_Form />} />
                        <Route path="/fileUpload" element={<UploadFileFormat />} />
                        <Route path="/listFiles" element={<List_Files />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </main>
            </div>
            <MobileMenu isOpen={isMobileMenuOpen} closeMobileMenu={closeMobileMenu} />
        </div>
    );
};

export default Layout;

import './App.css';
import { QueryClient, QueryClientProvider } from "react-query";
import Login from './pages/Login_Admin.jsx';
import { BrowserRouter, Route, Router, Routes, useNavigate } from 'react-router-dom';
import Layout from './layout/Layout.jsx';
import PrivateRoute from './Routes/PrivateRoute.jsx'
import EmailPrivateRoute from './Routes/EmailPrivateRoute.jsx'
import OTP from './pages/User Login/OTP.jsx'
import NewForm from './pages/NewForm/NewForm.jsx';
import FormScreen from './pages/User Form/FormScreen.jsx';
import FormDetails from './pages/User Form/FormDetails.jsx';
import Login_Redirect from './pages/User Login/Login_redirect.jsx';
import Login_User from './pages/User Login/Login_User.jsx';
import { ModalProvider } from './component/UI Component/OpenModal.jsx';

const queryclient = new QueryClient();
function App() {

  return (
    <>
      <QueryClientProvider client={queryclient}>
        <ModalProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/:storeId' element={<Login_Redirect />} />
              <Route path='/login' element={<Login_User />} />
              {/* <Route element={<PrivateRoute element={<Layout />} />}> */}
              <Route path='/layout/*' element={<Layout />} />
              {/* </Route> */}
              <Route element={<EmailPrivateRoute />}>
                <Route path='/user-otp' element={<OTP />} />
              </Route>
              <Route path='/defaultform' element={<NewForm />} />
              <Route path='/user/form' element={<FormScreen />} />
              <Route path='/user/form/:id' element={<FormDetails />} />
            </Routes>
          </BrowserRouter>
        </ModalProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;

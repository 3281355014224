import React from 'react'
import {Outlet,Navigate, useParams} from 'react-router-dom'

const EmailPrivateRoute = () => {

  // const {storeId} = useParams()
  const storeId = localStorage.getItem("storeId")
  return storeId? <Outlet/>: <Navigate to="/:storeId"/>
}

export default EmailPrivateRoute
import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => {
  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-800 bg-opacity-50 pointer-events-none">
      <CircularProgress color="secondary" />
    </div>
  );
};

export default Loader;

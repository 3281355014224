import form from "./icon/form-svg.svg"
import close from "./icon/close-svg.svg"
import checkmark from "./icon/check-mark-svgrepo-com.svg"
import searchI from "./icon/search-icon.svg"

export const Icons = {
    form,
    close,
    checkmark,
    searchI
}
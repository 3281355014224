import React from 'react'

const Card = ({children,className}) => {
    return (
            <div className={`  p-4 bg-white border border-gray-200 shadow rounded-lg dark:bg-white-300 dark:border-gray-300 mt-3 ${className}`}>
               {children}
            </div>
    )
}

export default Card;
import React from 'react';
// import Layout from './Layout';
import Users from '../images/users.png'
import Sales from '../images/Sales.png'
import NewSignup from '../images/Mobile login 1.png'
import { useFetch } from '../hooks/Api';
const Dashboard = () => {
    const localStorageData = localStorage.getItem('items')
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null

    const { data: storeData } = useFetch(process.env.REACT_APP_API_STORE_MANAGERS)

    const { data: userData } = useFetch(process.env.REACT_APP_API_USERS)

    const { data: formData } = useFetch(process.env.REACT_APP_API_FORMS)


    // console.log(data)
    return (
        <div className="p-4 space-y-4">
            {/* Header */}
            <div className="text-2xl font-bold text-gray-800">Dashboard</div>

            {/* Statistics */}

            {tokenData?.roles === 'admin' ?
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                    <div className="bg-white shadow rounded-lg p-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <div className="text-pink-600 font-bold">Users</div>
                                <div className="text-2xl font-bold text-gray-800">{userData?.totalRows ? userData?.totalRows : 0}</div>
                            </div>
                            <div>
                                <img src={Users} alt="Users" className="h-18 w-16 text-white-300" />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded-lg p-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <div className="text-pink-600 font-bold">Store Managers</div>
                                <div className="text-2xl font-bold text-gray-800">{storeData?.totalRows ? storeData?.totalRows : 0}</div>
                            </div>
                            <div>
                                <img src={Sales} alt="Sales" className="h-18 w-16 text-white-300" />
                            </div>

                        </div>
                    </div>
                    <div className="bg-white shadow rounded-lg p-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <div className="text-pink-600 font-bold">Total Forms</div>
                                <div className="text-2xl font-bold text-gray-800">{formData?.totalRows ? formData?.totalRows : 0}</div>
                            </div>
                            <div>
                                <img src={NewSignup} alt="Sales" className="h-18 w-16 text-white-300" />
                            </div>
                        </div>
                    </div>
                </div>
                : ""}

            {/* Recent Activities */}
            {/* {tokenData?.roles === 'store'?
            <div className="bg-white shadow rounded-lg p-4">
               <h2 className='ms-4'>Store QR-Code</h2>
                <img src={tokenData?.qrcode}/>
               
            </div>
                :""} */}

            {tokenData?.roles === 'store' ? (
                <div className="bg-white shadow rounded-lg p-4">
                    <h2 className="ms-4">Store QR-Code</h2>
                    <img src={tokenData?.qrcode} alt="Store QR Code" className="mb-4" width={250} height={250} />
                    <a href={tokenData?.qrcode} download="store-qr-code.png">
                        <button className="bg-pink-500 text-white px-4 py-2  ml-10 rounded hover:bg-pink-600">
                            Download QR Code
                        </button>
                    </a>
                </div>
            ) : ""}

            {/* Chart */}
            <div className="bg-white shadow rounded-lg p-4">
                <div className="text-gray-600 mb-2">Chart</div>
                <div className="h-64 bg-gray-100 flex items-center justify-center text-gray-500">
                    [Upcoming]
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdDeleteOutline } from 'react-icons/md';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios';

const List_Files = () => {
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_API_GET_ALL_FILES?.toString();
    const apiDelete = process.env.REACT_APP_API_DELETE_FILES?.toString();
    const { data, error } = useFetch(apiname);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [getData,setGetData] = useState([])

    useEffect(()=>{
        if(data){
            setGetData(data)
        }
    },[data])
    const totalPages = Math?.ceil(data?.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = getData?.slice(indexOfFirstItem, indexOfLastItem); 
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDelete = async (filename) => {

        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}${apiDelete}/${filename}`);
            setGetData(prevData => prevData.filter(file => file !== filename))
        } catch (error) {
        }
    };

    return (
        <>
            <div className='flex justify-end mb-2'>
                <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={() => navigate('/layout/fileUpload')}>
                    Upload New File
                </button>
            </div>
            {data?.length === 0 ? (
                <p className='flex justify-center text-3xl'>No files found</p>
            ) : (
                <>
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-white uppercase bg-pink-600 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Sr No.</th>
                                    <th scope="col" className="px-6 py-3">File Name</th>
                                    {/* <th scope="col" className="px-6 py-3">File Preview</th> */}
                                    {/* <th scope="col" className="px-6 py-3">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.map((e, index) => (
                                    <tr key={e.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-pink-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-6 py-4">{index + 1 + indexOfFirstItem}</td>
                                        <td className="px-6 py-4">{e}</td>
                                        {/* <td className="px-6 py-4">{e.name}</td> */}
                                        {/* <td className='px-6 py-4'>
                                            <button className='text-md' onClick={() => handleDelete(e)}>
                                                <MdDeleteOutline size={23} style={{color:'#DB2777'}}/>
                                            </button>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-end mt-4">
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                // color="primary"
                                variant="outlined"
                                shape="rounded"
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        color: '#DB2777',
                                        borderColor: '#DB2777',
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: '#DB2777',
                                        color: '#ffffff',
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        // color: '#DB2777',
                                    },
                                }}
                            />
                        </Stack>
                    </div>
                </>
            )}
        </>
    );
};

export default List_Files;

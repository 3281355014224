// import React, { useEffect, useState } from 'react'
// import { useForm, Controller } from 'react-hook-form'
// import Latan from '../../images/copy.png'
// import { useCreate } from '../../hooks/Api';
// import axios from 'axios';
// import { Snackbar } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import Signature from '../../component/signpad';
// import Loader from '../../component/UI Component/Loader';

// const NewForm = () => {
//     const apiname = process.env.REACT_APP_API_FORM_ANS?.toString();
//     const navigate = useNavigate();
//     const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
//     const { register, handleSubmit, formState: { errors }, watch } = useForm();
//     const [signatureData, setSignatureData] = useState({});
//     const [requiredSignatures, setRequiredSignatures] = useState(new Set());
//     const [loading, setLoading] = useState(false);


//     const [state, setState] = useState({
//         open: false
//     });
//     const [details, setDetails] = useState([])
//     const [localData, setLocalData] = useState([])
//     useEffect(() => {
//         const getFormData = JSON.parse(localStorage.getItem("items"))
//         setLocalData(getFormData)
//         console.log(getFormData)
//         const getData = modifyData(JSON.parse(getFormData?.default_form?.form_fields))
//         setDetails(getData)
//     }, [])

//     const modifyData = (data) => {
//         if (!Array.isArray(data)) {
//             console.error('Invalid data format:', data);
//             return [];
//         }
//         const getData = data.form_fields ? data.form_fields : data
//         const modifyData = getData.map(details => {

//             if (details.type === "checkbox") {
//                 details.checkBoxOption = details?.options?.map(optionObject => {
//                     const key = Object.keys(optionObject)[0];
//                     return String(optionObject[key]);
//                 });
//                 delete details.options;
//             }

//             if (details.type === "radio") {
//                 details.radioOptions = details?.options?.map(optionObject => {
//                     const key = Object.keys(optionObject)[0];
//                     return String(optionObject[key]);
//                 });
//                 delete details.options;
//             }

//             if (details.type === "dropdown") {
//                 details.option = details?.dropdownOption?.map(option => ({
//                     value: option.value,
//                     label: option.label
//                 }));
//                 delete details.options;
//             }

//             if (["short", "paragraph", "date", "sign pad"].includes(details.type)) {
//                 delete details.checkBoxOption;
//                 delete details.radioOptions;
//                 delete details.option;
//                 if (details.type === "date") {
//                     details.format = "DD/MM/YYYY";
//                 }
//             }
//             return details;
//         });

//         return modifyData
//     };

//     const calculateTotal = (array) => {
//         return array.reduce((total, item) => {
//             if (item.type === "dropdown") {
//                 const answerNumber = Number(item.answer);
//                 if (!isNaN(answerNumber)) {
//                     return total + answerNumber;
//                 } else {
//                     console.error(`Invalid number for answer: ${item.answer}`);
//                 }
//             }
//             return total;
//         }, 0);
//     };

//     const onSubmit = data => {
//         try {
//             setLoading(true)
//             const finalData = details.map(field => {
//                 if (field.type === 'sign pad') {
//                     return {
//                         ...field,
//                         answer: signatureData[field.Key] || ''
//                     };
//                 } else if (field.type === 'radio') {
//                     const radioTextDataKeys = field?.radioTextData?.map(option => `${field.Key}_radiotext_${option.Key}`);
//                     const updatedRadioTextData = field?.radioTextData?.map(option => {
//                         const dataKey = `${field?.Key}_radiotext_${option?.Key}`;
//                         return {
//                             ...option,
//                             answer: data[dataKey] || ''
//                         };
//                     });
//                     delete field.dropdownOption;
//                     return {
//                         ...field,
//                         answer: data[field.Key] || '',
//                         radioTextData: updatedRadioTextData
//                     };
//                 } else {
//                     const formValue = data[field.Key];
//                     return {
//                         ...field,
//                         answer: formValue !== undefined ? formValue : ''
//                     };
//                 }
//             });

//             const totalCount = calculateTotal(finalData);

//             const newObject = {
//                 answer: totalCount,
//                 Key: 'total'
//             };

//             finalData.push(newObject);

//             const finalObject = {
//                 form_name: localData?.default_form?.form_name,
//                 form_fields: finalData,
//                 form_description: localData?.default_form?.form_description,
//                 file_name: localData?.default_form?.file_name
//             }
//             mutate({ form_id: localData?.default_form?.id, user_id: localData.id, store_id: localData.storeId, answers: finalObject, file_name: localData?.default_form?.file_name, email: localData.email, mobile:localStorage.getItem('mobile') })
//         } catch (error) {
//             console.log("🚀 ~ onSubmit ~ error:", error)
//             setLoading(false)
//         }
//     }

//     useEffect(() => {
//         if (isSuccess) {
//             updateUser()
//             setLoading(false)
//         }
//         else if (isError) {
//             setState({ open: true })
//             setLoading(false)
//         }
//     }, [isSuccess, data, error, isError])

//     const updateUser = async () => {
//         try {
//             let data = [`${localData?.default_form?.id}`]
//             localData.completed_forms = data;
//             localStorage.setItem("items", JSON.stringify(localData))
//             const url = `${process.env.REACT_APP_BASE_URL}api/updateCustomerInfo/${localData.id}`;
//             const response = await axios.put(url, { completed_forms: [localData?.default_form?.id] }, {
//                 headers: {
//                     'authorization': `Bearer ${localData
//                         ?.token}`
//                 }
//             });
//             if (response.status === 200) {
//                 navigate(`/user/form`)
//             }
//             setLoading(false)
//         } catch (error) {
//             console.error('API call error:', error);
//             setLoading(false)
//             throw error;
//         }
//     }

//     const handleSignatureData = (data, fieldKey) => {
//         setSignatureData(prevState => ({
//             ...prevState,
//             [fieldKey]: data
//         }));
//     };

//     const isSubmitDisabled = () => {
//         return Array.from(requiredSignatures).some(key => !signatureData[key]);
//     };
//     const watchRadioValues = watch();

//     return (
//         <>
//             <div>
//                 <div className="container mx-auto mt-5">

//                     <div className="bg-white shadow-lg rounded-lg p-6 mb-4 border-2 border-gray-300">

//                         <div className="flex justify-between mb-4">
//                             <div className="flex-none w-1/6">
//                                 <img className="w-full h-auto rounded-lg" src={Latan} alt="Logo" />
//                             </div>
//                             <div className="flex-grow text-center">
//                                 <h1 className="text-4xl font-bold text-[#003060]">L.A.TAN<sub>&reg;</sub> RELEASE FORM</h1>
//                             </div>
//                             <div className="flex-none w-1/6"></div>
//                         </div>

//                         <form onSubmit={handleSubmit(onSubmit)}>
//                             <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                                 {details?.map((field, index) => {
//                                     switch (field.type) {
//                                         case 'short':
//                                             return (
//                                                 <div key={index} className="flex flex-col">
//                                                     <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
//                                                     <input
//                                                         {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                         className='w-full border border-gray-300 rounded-lg p-2'
//                                                     />
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                 </div>
//                                             );

//                                         case 'paragraph':
//                                             return (
//                                                 <div key={index} className="flex flex-col">
//                                                     <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
//                                                     <textarea
//                                                         {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                         className='w-full border border-gray-300 rounded-lg p-2'
//                                                     />
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                 </div>
//                                             );

//                                         case 'radio':
//                                             return (
//                                                 <div key={index} className="flex flex-col">
//                                                     <label className='text-lg font-medium text-black mb-2'>{field?.label}</label>
//                                                     {field?.radioOptions?.map((option, idx) => (
//                                                         <div key={idx} className="flex items-center mb-1">
//                                                             <input
//                                                                 type="radio"
//                                                                 id={`${field.Key}-${idx}`}
//                                                                 {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                                 value={option}
//                                                                 className='mr-2'
//                                                             />
//                                                             <label htmlFor={`${field.Key}-${idx}`}>{option}</label>
//                                                         </div>
//                                                     ))}
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                     {
//                                                         field.radioText === true && (
//                                                             <>
//                                                                 {
//                                                                       field?.radioTextData?.map((textField, idx) => {
//                                                                         const selectedValue = (watchRadioValues[field.Key] || '').toLowerCase();
//                                                                         const isRequired = selectedValue === 'yes';
//                                                                         const isNo = selectedValue === 'no';
//                                                                         return (
//                                                                             <>
//                                                                             {isNo ? null : 
//                                                                             <>
//                                                                             <div key={idx} className="flex flex-col">
//                                                                                 <label className='text-lg font-medium text-black mb-2'>{textField.label}</label>
//                                                                                 <input
//                                                                                     type='text'
//                                                                                     name={`${field.Key}_radiotext_${textField.Key}`}
//                                                                                     className='w-full border border-gray-300 rounded-lg p-2'
//                                                                                     disabled={isRequired ? false : true}
//                                                                                     {...register(`${field.Key}_radiotext_${textField.Key}`, { required: isRequired ? { value: true, message: `${textField.label} is required` } : false })}
//                                                                                 />
//                                                                                 {errors[`${field.Key}_radiotext_${textField.Key}`] && <span className='text-red-500 text-sm mt-1'>{errors[`${field.Key}_radiotext_${textField.Key}`]?.message}</span>}
//                                                                             </div>
//                                                                             </>}
                                                                            
//                                                                             </>
//                                                                         );
//                                                                     })
//                                                                 }

//                                                             </>
//                                                         )
//                                                     }
//                                                 </div>
//                                             );

//                                         case 'checkbox':
//                                             return (
//                                                 <div key={index} className="flex flex-col">
//                                                     <label className='text-lg font-medium text-black mb-2'>{field.label}</label>
//                                                     {field.checkBoxOption.map((option, idx) => (
//                                                         <div key={idx} className="flex items-center mb-1">
//                                                             <input
//                                                                 type="checkbox"
//                                                                 id={`${field.Key}-${idx}`}
//                                                                 value={option}
//                                                                 {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                                 className='mr-2'
//                                                             />
//                                                             <label htmlFor={`${field.Key}-${idx}`}>{option}</label>
//                                                         </div>
//                                                     ))}
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                 </div>
//                                             );

//                                         case 'dropdown':
//                                             return (
//                                                 <div key={index} className="flex flex-col">
//                                                     <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
//                                                     <select
//                                                         name={field.Key}
//                                                         {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                         className='w-full border border-gray-300 rounded-lg p-2'
//                                                     >
//                                                         <option value="" >Select an option</option>
//                                                         {field.option.map((data, idx) => (
//                                                             <option key={idx} value={data.value}>
//                                                                 {data.label}
//                                                             </option>
//                                                         ))}
//                                                     </select>
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                 </div>
//                                             );

//                                         case 'date':
//                                             return (
//                                                 <div key={index} className="flex flex-col">
//                                                     <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
//                                                     <input
//                                                         type="date"
//                                                         {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                         className='w-full border border-gray-300 rounded-lg p-2'
//                                                     />
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                 </div>
//                                             );

//                                         case 'sign pad':
//                                             return (
//                                                 <div key={index} className={`flex flex-col `}>
//                                                     <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
//                                                     <Signature
//                                                         fieldKey={field.Key}
//                                                         divclass="!border-2 !border-gray-800 md:!max-w-[200px] md:!max-w-[400px] !w-full !h-full rounded-lg"
//                                                         sendSing={(data) => handleSignatureData(data, field.Key)}
//                                                         showButton={true}
//                                                     />
//                                                     {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
//                                                 </div>
//                                             );


//                                             case 'button':
//                                             return (
//                                                 <div key={index} className={`flex flex-col `}>
//                                                     <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
//                                                     <button
//                                                         type="button"
//                                                         // {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
//                                                         className='w-full border border-gray-300 rounded-lg p-2'
//                                                     />
//                                                     {/* {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>} */}
//                                                 </div>
//                                             );

//                                         default:
//                                             return null;
//                                     }
//                                 })}
//                             </div>

//                             <div className="mt-6 text-center flex justify-center">
//                                 <button className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 w-56 flex items-center justify-center" type='submit' disabled={isLoading}>
//                                     Submit
//                                     {isLoading && <div className="w-4 h-4 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
//                                 </button>
//                             </div>
//                         </form>

//                     </div>
//                 </div>

//             </div>
//             <Snackbar
//                 anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//                 open={state.open}
//                 onClose={() => {
//                     setState({
//                         ...state,
//                         open: false,
//                     });
//                 }}
//                 TransitionComponent={state.Transition}
//                 message={error?.message ? error?.message : 'Something Went Wrong !'}
//                 key={state?.Transition?.name}
//                 autoHideDuration={3000}
//             />
//             {
//                 loading ? (
//                     <Loader />
//                 ) : null
//             }
//         </>
//     )
// }

// export default NewForm






import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Latan from '../../images/copy.png';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Signature from '../../component/signpad';
import { useCreate } from '../../hooks/Api';
import { Snackbar } from '@mui/material';
import Loader from '../../component/UI Component/Loader';

const apiname = process.env.REACT_APP_API_FORM_ANS?.toString();

const NewForm = () => {
    const { id } = useParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const navigate = useNavigate();
 const completed = JSON.parse(localStorage.getItem('completed'));
    const completedForms = completed ? completed : false;
    const { register, handleSubmit, formState: { errors }, setValue, trigger, watch } = useForm();
    const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
    const [details, setDetails] = useState([]);
    const [formDetails, setFormDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [signatureData, setSignatureData] = useState({});
    const [requiredSignatures, setRequiredSignatures] = useState(new Set());
    const [localData, setLocalData] = useState([]);
    const [currentStep, setCurrentStep] = useState(0); // Step state
    const [state, setState] = React.useState({
        open: false
    });

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    // useEffect(() => {
    //     const completed = JSON.parse(localStorage.getItem('items'));
    //     setLocalData(completed)
    //     fetchData()
    // }, [id])


    useEffect(() => {
                const getFormData = JSON.parse(localStorage.getItem("items"))
                setLocalData(getFormData)
                const getData = modifyData(JSON.parse(getFormData?.default_form?.form_fields))
                setDetails(getData)
            }, [])
    // useEffect(() => {
    //     fetchData();
    // }, [id]);

    // useEffect(() => {
    //     if (details.length) {
    //         const required = new Set(details?.filter(field => field?.type === 'sign pad' && field?.required).map(field => field?.Key));
    //         setRequiredSignatures(required);
    //     }
    // }, [details]);


    
    useEffect(() => {
        const getDatatype = typeof (formDetails?.form_fields)
        const getparseData = getDatatype === "string" ? JSON.parse(formDetails?.form_fields) : formDetails?.form_fields
        if (getparseData) {
            getparseData.forEach(field => {
                if (field.answer !== undefined) {
                    setValue(field.Key, field.answer);
                }
                if (field.radioTextanswer !== undefined) {
                    setValue(`${field.Key}_radioText`, field.radioTextanswer);
                }
                if (field.type === 'sign pad' && field.answer) {
                    setSignatureData(prevState => ({
                        ...prevState,
                        [field.Key]: field.answer
                    }));
                }
            });
        }
    }, [formDetails, setValue]);


    // const fetchData = async () => {
    //     try {
    //         setLoading(true)
    //         const localStorageData = localStorage.getItem('items');
    //         const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
    //         if (completedForms) {
    //             const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${getApi}/${tokenData.id}/${id}`, {
    //                 headers: {
    //                   'Content-Type': 'application/pdf',
    //                   'authorization': `Bearer ${tokenData?.token}`
    //                 },
    //                 responseType: 'blob' // Important: This tells axios to treat the response as binary data
    //               })

    //               const blobUrl = URL.createObjectURL(response.data);
    //               setPdfUrl(blobUrl); 

                
    //         } else {
              

    //             try {
    //                 setLoading(true);
    //                 const localStorageData = localStorage.getItem('items');
    //                 const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
        
    //                 const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname1}/${id}`, {
    //                     headers: {
    //                         'authorization': `Bearer ${tokenData?.token}`
    //                     }
    //                 });
    //                 setFormDetails(res.data.results);
    //                 const getDetails = JSON.parse(res.data.results.form_fields);
    //                 const modifiedData = modifyData(getDetails);
    //                 setDetails(modifiedData);
        
    //                 setLoading(false);
    //             } catch (error) {
    //                 console.error('Error fetching data:', error);
    //                 setLoading(false);
    //             }
    //         }
    //         setLoading(false)
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setLoading(false)
    //     }
    // };

    // const fetchData = async () => {
       
    // };


    const calculateTotal = (array) => {
        return array.reduce((total, item) => {
            if (item.type === "dropdown") {
                const answerNumber = Number(item.answer);
                if (!isNaN(answerNumber)) {
                    return total + answerNumber;
                } else {
                    console.error(`Invalid number for answer: ${item.answer}`);
                }
            }
            return total;
        }, 0);
    };

    // const modifyData = (data) => {

        const modifyData = (data) => {
            if (!Array.isArray(data)) {
                console.error('Invalid data format:', data);
                return [];
            }
            const getData = data.form_fields ? data.form_fields : data
            const modifyData = getData.map(details => {
    
                if (details.type === "checkbox") {
                    details.checkBoxOption = details?.options?.map(optionObject => {
                        const key = Object.keys(optionObject)[0];
                        return String(optionObject[key]);
                    });
                    delete details.options;
                }
    
                if (details.type === "radio") {
                    details.radioOptions = details?.radioOptions?.map(optionObject => {
                        // const key = Object.keys(optionObject)[0];
                        return optionObject;
                    });
                    if (details?.options) {
                        details.radioOptions = details?.options?.map(optionObject => {
                            const key = Object.keys(optionObject)[0];
                            return String(optionObject[key]);
                        });
                    }
                    delete details.options;
                }
    
                if (details.type === "dropdown") {
                    details.option = details?.dropdownOption?.map(option => ({
                        value: option.value,
                        label: option.label
                    }));
                    delete details.options;
                }
    
                if (["short", "paragraph", "date", "sign pad"].includes(details.type)) {
                    delete details.checkBoxOption;
                    delete details.radioOptions;
                    delete details.option;
                    if (details.type === "date") {
                        details.format = "DD/MM/YYYY";
                    }
                }
                return details;
            });
            return modifyData
        };
   

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            // const getParseData = JSON.parse(formDetails?.form_fields);
            const finalData = details?.map(field => {
                if (field.type === 'sign pad') {
                    return { ...field, answer: signatureData[field.Key] || '' };
                } 
                
       

        else if (field.type === 'radio') {
            const radioTextDataKeys = field?.radioTextData?.map(option => `${field.Key}_radiotext_${option.Key}`);
            const updatedRadioTextData = field?.radioTextData?.map(option => {
                const dataKey = `${field.Key}_radiotext_${option.Key}`;
                return {
                    ...option,
                    answer: data[dataKey] || ''
                };
            });
            delete field.dropdownOption;
            return {
                ...field,
                answer: data[field.Key] || '',
                radioTextData: updatedRadioTextData
            };
        } else {
            const formValue = data[field.Key];
            return {
                ...field,
                answer: formValue !== undefined ? formValue : ''
            };
        }
    });

    const totalCount = calculateTotal(finalData);

    const newObject = {
        answer: totalCount,
        Key: 'total'
    };

    finalData.push(newObject);

    const finalObject = {
        form_name: localData?.default_form?.form_name,
                        form_fields: finalData,
                        form_description: localData?.default_form?.form_description,
                        file_name: localData?.default_form?.file_name
    };

    // mutate({ form_id: formDetails?.id, user_id: localData?.id, store_id: localData?.storeId, answers: finalObject, file_name: formDetails?.file_name, email: localData?.email,  mobile: localStorage.getItem('mobile') });
                mutate({ form_id: localData?.default_form?.id, user_id: localData.id, store_id: localData.storeId, answers: finalObject, file_name: localData?.default_form?.file_name, email: localData.email, mobile:localStorage.getItem('mobile') })

} catch (error) {
    console.log("🚀 ~ onSubmit ~ error:", error)
    setLoading(false)
}
    };

    // Stepper Logic
    // const handleNext = async () => {
    //     const valid = await trigger(); // Validate current step
    //     if (valid) setCurrentStep(prevStep => prevStep + 1);
    // };

    // const handlePrevious = () => setCurrentStep(prevStep => Math.max(prevStep - 1, 0));

    const groupFieldsByButtons = (fields) => {
        const groups = [];
        let group = [];

        fields.forEach(field => {
            if (field.type === 'button') {
                if (group.length) {
                    groups.push(group);
                    group = [];
                }
            } else {
                group.push(field);
            }
        });

        if (group.length) {
            groups.push(group);
        }

        return groups.filter(group => group.length > 0); // Filter out empty groups
    };
    
    const groupedFields = groupFieldsByButtons(details);

    // const handleNext = async () => {
    //     const valid = await trigger(); // Validate current step
    //     if (valid && !isLastStep) {
    //         setCurrentStep(prevStep => prevStep + 1);
    //     }
    // };

    const handleNext = async () => {
        const valid = await trigger(); // Validate current step fields
        const requiredSignaturesForStep = groupedFields[currentStep].filter(field => field.type === 'sign pad' && field.required);
        
        // Check if required signatures are filled
        const allSignaturesFilled = requiredSignaturesForStep.every(field => signatureData[field.Key]);
        
        // Proceed only if form is valid and all required signatures are filled
        if (valid && allSignaturesFilled && !isLastStep) {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };
    
    // Function to check if the "Next" button should be disabled
    const isNextButtonDisabled = () => {
        const requiredSignaturesForStep = groupedFields[currentStep].filter(field => field.type === 'sign pad' && field.required);
        
        // Disable Next button if any required signatures are not filled
        const allSignaturesFilled = requiredSignaturesForStep.every(field => signatureData[field.Key]);
        
        return !allSignaturesFilled;
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(prevStep => prevStep - 1);
        }
    };


    // Filter out button fields and check if we are at the last non-button step
    const nonButtonFields = details.filter(field => field.type !== 'button');
    const isLastStep = currentStep === groupedFields.length - 1;

    const renderFormField = (field, index) => {
        if (field.type === 'button') return null; // Skip button fields
        switch (field.type) {
            case 'short':
                return (
                    <div key={field.Key} className="flex flex-col">
                        <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
                        <input
                            {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                            className='w-full border border-gray-300 rounded-lg p-2'
                        />
                        {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                    </div>
                );
            case 'paragraph':
                return (
                    <div key={field.Key} className="flex flex-col">
                        <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
                        <textarea
                            {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                            className='w-full border border-gray-300 rounded-lg p-2'
                        />
                        {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                    </div>
                );
            case 'radio':
                return (
                    <div key={field.Key} className="flex flex-col">
                        <label className='text-lg font-medium text-black mb-2'>{field.label}</label>
                        {field.radioOptions.map((option, idx) => (
                            <div key={idx} className="flex items-center mb-1">
                                <input
                                    type="radio"
                                    {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                                    value={option}
                                    className='mr-2'
                                />
                                <label>{option}</label>
                            </div>
                        ))}
                        {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                        {
                                                        field.radioText === true && (
                                                            <>
                                                                {
                                                                    field?.radioTextData?.map((textField, idx) => {
                                                                        const selectedValue = (watchRadioValues[field.Key] || '').toLowerCase();
                                                                        const isRequired = selectedValue === 'yes';
                                                                        const isDisabled = !(selectedValue === 'yes') || completedForms;
                                                                        const isNo = selectedValue === 'no';
                                                                        
                                                                        return (
                                                                            <>
                                                                            {isNo ? null : 
                                                                            <>
                                                                            <div key={idx} className="flex flex-col">
                                                                                <label className='text-lg font-medium text-black mb-2'>{textField.label}</label>
                                                                                <input
                                                                                    type='text'
                                                                                    name={`${field.Key}_radiotext_${textField.Key}`}
                                                                                    disabled={isDisabled}
                                                                                    defaultValue={completedForms ? textField?.answer : ""}
                                                                                    className='w-full border border-gray-300 rounded-lg p-2'
                                                                                    {...register(`${field.Key}_radiotext_${textField.Key}`, { required: isRequired ? { value: true, message: `${textField.label} is required` } : false })}
                                                                                />
                                                                                {errors[`${field.Key}_radiotext_${textField.Key}`] && <span className='text-red-500 text-sm mt-1'>{errors[`${field.Key}_radiotext_${textField.Key}`]?.message}</span>}
                                                                            </div>
                                                                            </>}
                                                                            
                                                                            </>
                                                                        );
                                                                    })
                                                                }

                                                            </>
                                                        )
                                                    }
                    </div>
                );

                case 'checkbox':
            return (
                <div key={field.Key} className="flex flex-col">
                    <label className='text-lg font-medium text-black mb-2'>{field.label}</label>
                    {field.checkBoxOption.map((option, idx) => (
                        <div key={idx} className="flex items-center mb-1">
                            <input
                                type="checkbox"
                                id={`${field.Key}-${idx}`}
                                value={option}
                                {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                                className='mr-2'
                            />
                            <label htmlFor={`${field.Key}-${idx}`}>{option}</label>
                        </div>
                    ))}
                    {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                </div>
            );

        case 'dropdown':
            return (
                <div key={field.Key} className="flex flex-col">
                    <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
                    <select
                        {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                        className='w-full border border-gray-300 rounded-lg p-2'
                    >
                        <option value="">Select an option</option>
                        {field.option.map((data, idx) => (
                            <option key={idx} value={data.value}>
                                {data.label}
                            </option>
                        ))}
                    </select>
                    {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                </div>
            );

        case 'date':
            return (
                <div key={field.Key} className="flex flex-col">
                    <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
                    <input
                        type="date"
                        {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                        className='w-full border border-gray-300 rounded-lg p-2'
                    />
                    {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                </div>
            );
            
            case 'sign pad':
                return (
                    <div key={field.Key} className="flex flex-col">
                        <label className='text-lg font-medium text-black mb-1'>{field.label}</label>
                        <Signature
                            fieldKey={field.Key}
                            divclass="border-2 border-gray-800 md:max-w-[200px] md:max-w-[400px] w-full h-full rounded-lg"
                            sendSing={(data) => handleSignatureData(data, field.Key)}
                            showButton={true}
                            initialData={signatureData[field.Key] || ''}
                        />
                        {errors[field.Key] && <span className='text-red-500 text-sm mt-1'>{errors[field.Key]?.message}</span>}
                    </div>
                );
            default:
                return null;
        }
    };



    const handleSignatureData = (data, fieldKey) => {
        setSignatureData(prevState => ({
            ...prevState,
            [fieldKey]: data
        }));
    };


    




   

    const isSubmitDisabled = () => {
        return Array.from(requiredSignatures).some(key => !signatureData[key]);
    };

    // const handleClose = () => {
    //     setState({
    //         ...state,
    //         open: false,
    //     });
    // };
console.log(isSuccess)
    useEffect(() => {
                if (isSuccess) {
                    updateUser()
                    setLoading(false)
                }
                else if (isError) {
                    setState({ open: true })
                    setLoading(false)
                }
            }, [isSuccess, data, error, isError])
        
            const updateUser = async () => {
                try {
                    let data = [`${localData?.default_form?.id}`]
                    localData.completed_forms = data;
                    localStorage.setItem("items", JSON.stringify(localData))
                    const url = `${process.env.REACT_APP_BASE_URL}api/updateCustomerInfo/${localData.id}`;
                    const response = await axios.put(url, { completed_forms: [localData?.default_form?.id] }, {
                        headers: {
                            'authorization': `Bearer ${localData
                                ?.token}`
                        }
                    });
                    if (response.status === 200) {
                        navigate(`/user/form`)
                    }
                    setLoading(false)
                } catch (error) {
                    console.error('API call error:', error);
                    setLoading(false)
                    throw error;
                }
            }
        
            // const handleSignatureData = (data, fieldKey) => {
            //     setSignatureData(prevState => ({
            //         ...prevState,
            //         [fieldKey]: data
            //     }));
            // };

    const watchRadioValues = watch()

    console.log('loading',isLoading)

    return (
        <>
            <div className="container mx-auto mt-5 px-4">
                <div className="bg-white shadow-lg rounded-lg p-6 mb-4 border-2 border-gray-300" style={{ minHeight: '100vh' }}>
                    <div className="flex flex-col md:flex-row justify-between mb-4">
                        <div className="flex-none w-full md:w-1/6 mb-4 md:mb-0">
                            <img className="w-full h-auto rounded-lg" src={Latan} alt="Logo" />
                        </div>
                        <div className="flex-grow text-center">
                            <h1 className="text-2xl md:text-4xl font-bold text-[#003060]">
                                L.A.TAN<sub>&reg;</sub> RELEASE FORM
                            </h1>
                        </div>
                    </div>

                    {pdfUrl ? (
        // <iframe src={pdfUrl} width="100%" style={{minHeight:'100vh'}} title="PDF Viewer"></iframe>
        <embed src={pdfUrl} type="application/pdf" width="100%" style={{ minHeight: '100vh' }} />
      ) : 
      null
      }

                    {!loading && groupedFields?.length > 0 && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* Render fields based on the current step */}
                                {/* {groupedFields.slice(currentStep, currentStep + 1).map((field, index) => (
                                    renderFormField(field, index)
                                ))} */}
                                 {groupedFields[currentStep]?.map((field, index) => renderFormField(field, index))}
                            </div>

                            <div className="mt-6 text-center flex justify-center space-x-4 ">

                                <div>
                                {currentStep > 0 && (
                                    <button
                                        type="button"
                                        className="bg-gray-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-400 "
                                        onClick={handlePrevious}
                                        // disabled={isSubmitDisabled()}
                                    >
                                        Previous
                                    </button>
                                )}
</div>

<div>

                                {!isLastStep && (
                                    <button
                                    type="button"
                                    className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 "
                                    onClick={handleNext}
                                    disabled={isNextButtonDisabled()}
                                    >
                                        Next
                                    </button>
                                )}
                                </div>

                                <div>


                                {isLastStep && (
                                    <button
                                    type="submit"
                                    className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 "
                                    disabled={isSubmitDisabled() || isLoading}
                                    >
                                        Submit
                                        {isLoading && <div className="w-4 h-4 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
                                    </button>
                                )}
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.open}
                onClose={() => {
                    setState({
                        ...state,
                        open: false,
                    });
                }}
                TransitionComponent={state.Transition}
                message={error?.message ? error?.message : 'Something Went Wrong !'}
                key={state?.Transition?.name}
                autoHideDuration={3000}
            />
            {
                loading ? (
                    <Loader />
                ) : null
            }
        </>
    );
};

export default NewForm;

import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../../jsonfils/Lotifile/OTP.json';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import  Snackbar  from '@mui/material/Snackbar';


const OTP = () => {
  const navigate = useNavigate()
  const storeId = localStorage.getItem("storeId")
  const email = localStorage.getItem("userEmail")
  // const { email, storeId } = useParams()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const apiname = process.env.REACT_APP_API_VERIFY_OTP.toString();
  const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiname}`, data)
    return postapicall.data
  })

  const onSubmit = (data) => {

    mutate({email:email,otp:data.otp})
  }

  useEffect(()=>{
    if(data)
      localStorage.setItem('items',JSON.stringify(data))
  },[data])

  const [state, setState] = React.useState({
    open: false
  });
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  useEffect(()=>{
    if (isSuccess) {
      if(data.completed_forms?.length){
        navigate(`/user/form`)
      }else{
        navigate(`/defaultform`)
      }
      // navigate('/layout/form/:storeId/:email')
    }
    else if(isError){
      console.log(isError,"isError");
      setState({open:true})
    }
  },[data,error,isSuccess])

  return (
    <>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row max-w-4xl p-6 rounded-lg ">

        <div className="hidden md:flex md:w-1/2 items-center justify-center p-4">
          <Lottie
            animationData={animationData}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>

        <div className="w-full md:w-1/2 p-6  bg-white shadow-lg">
          <h2 className="text-2xl font-bold text-center mb-6">OTP Verification </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
                OTP
              </label>
              <div className="flex items-center border border-gray-300 rounded-md p-2">
                <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11v2m-6 4a9 9 0 1112 0M12 7h.01" />
                </svg>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter OTP"
                  {...register('otp', { required: { value: true, message: "OTP is required" } })}
                />
              </div>
              <p style={{ color: "Red" }}>{errors?.otp?.message}</p>
            </div>
            <button type='submit' className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700">

              <div className='flex justify-center'>

                <div className='mx-2'>
                  <span> Login</span>
                </div>

                <div>
                  {isLoading && <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
                </div>

              </div>

            </button>
          </form>
        </div>
      </div>
    </div>
    <Snackbar
     anchorOrigin={{ vertical:'top', horizontal:'right' }}
       open={state.open}
       onClose={handleClose}
       TransitionComponent={state.Transition}
       message={error?.response?.data?.message || data?.message}
       key={state?.Transition?.name}
       autoHideDuration={3000}
       />
    </>
  );
};

export default OTP;


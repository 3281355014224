import React, { createContext, useState, useContext } from 'react';
import { Modal, Button } from 'antd';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openModal = (props) => {
    setModalProps(props);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setModalProps({});
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        title={modalProps.title}
        visible={isModalVisible}
        onOk={() => {
          modalProps.onYes && modalProps.onYes();
          closeModal();
        }}
        onCancel={() => {
          modalProps.onNo && modalProps.onNo();
          closeModal();
        }}
        footer={[
          <Button key="ok" type="primary" onClick={() => {
            modalProps.onYes && modalProps.onYes();
            closeModal();
          }}>
            OK
          </Button>,
          <Button key="cancel" onClick={() => {
            modalProps.onNo && modalProps.onNo();
            closeModal();
          }}>
            Cancel
          </Button>,
        ]}
      >
        <p>{modalProps.text}</p>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
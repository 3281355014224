import React, { useRef, useEffect, useState } from "react";
import SignaturePad from "react-signature-canvas";

const Signature = ({ sendSing = () => {}, fieldKey, divclass, showButton,initialData  }) => {
  const signPad = useRef(null);
  // const [data, setData] = useState("");
  // const [signDone, setSignDone] = useState(false);

  const [data, setData] = useState(initialData || ""); // Initialize with initialData if available
  const [signDone, setSignDone] = useState(!!initialData);

  const emptyCanvasString = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAACCCAYAAABo1p7oAAAAAXNSR0IArs4c6QAABKRJREFUeF7t1bENAAAIwzD6/9P8kNnsXSyk7BwBAgQIEAgCCxsTAgQIECBwAuIJCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAg89YgCDCs6wuAAAAABJRU5ErkJggg==";

  useEffect(() => {
    if (signDone || data !==emptyCanvasString) {
      sendSing(data, fieldKey);
      setData(data);
    }
  }, [data,fieldKey]);

  const clear = () => {
    setSignDone(false);
    signPad.current.clear();
    setData("");
  };

  const save = () => {
    const signatureData = signPad.current.toDataURL();
    setSignDone(true);
    setData(signatureData);
  };

//   useEffect(() => {
//     if (signPad.current && signPad.current._data) { // Ensure the pad is initialized
//         if (initialData) {
//             signPad.current.clear();  // Clear the canvas first
//             signPad.current.fromDataURL(initialData); // Load the initial signature
//         }
//     }
// }, [initialData]);

useEffect(() => {
  if (initialData && signPad.current) {
      signPad.current.clear();  // Clear the canvas first
      signPad.current.fromDataURL(initialData); // Load the initial signature
  }
}, [initialData]);


  return (
    <div className={` flex flex-col font-serif justify-center items-center ${divclass} shadow-lg shadow-slate-400 md:w-full w-full`}>
      <div className="">
        <p className="flex justify-center items-start">Your Signature</p>
        <div className="flex justify-center items-center flex-col">
          <SignaturePad
            canvasProps={{height: 220, width:400,className: "sigCanvas md:!w-[350px] !w-full bg-slate-200" }}
            ref={signPad}
            onEnd={save}
          />
          {showButton && (
            <>
              <div className="flex space-x-4">
                <button type="button" onClick={clear}>Clear</button>
                {/* <button type="button" onClick={save}>Done</button> */}
              </div>
              {(!signDone || data === emptyCanvasString) ? (
                <p className="text-red-500">Please Sign and Done.</p>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signature;
